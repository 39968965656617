#cms{
  .page-content{
    padding: 0;
  }
}

.cms-header{
  position: relative;

  img{
    width: 100%;
    @media screen and (min-width: 576px){
      width: 75%;
    }
  }

  .color-container{
    width: 100%;
    display: none;

    @media screen and (min-width: 576px){
      display: block;
      width: 75%;
      position: absolute;
      right: 0;
      left: unset;
      top: 50%;
      transform: translateY(-50%);
    }

    @media screen and (min-width: 992px){
      width:50%;
    }

  }

  .text-container{
    @extend .color-container;
    padding: 1rem;
    color: $barry-white;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.green{
      background-color: $green-day;
    }

    &.pink{
      background-color: $pink-floyd;
    }

    &.blue{
      background-color: $ice-cube;
    }

    &.yellow{
      background-color: $yellow-claw;
    }

    @media screen and (min-width: 576px){
      padding: 2rem 5rem;
      text-align: left;

      &.yellow, &.blue, &.pink, &.green{
        background-color: transparent;
      }
    }

    h1{
      padding-bottom: 1rem;
      text-align: center;

      @media screen and (min-width: 576px){
        text-align: left;
      }

      span{
        display: block;
        @media screen and (min-width: 992px){
          font-size: 1.3em;
        }
      }


    }
  }
}

.cms-text-center{
  margin: 1.5em 0;

  h3{
    font-weight: bold;
    font-size: 2em;
  }
}

.cms-blocks{
  @extend .cms-text-center;
  background-color: lighten($macy-gray, 24%);
  margin-bottom: 3em;
  padding: 2em 0;

  h2{
    @extend .h1;
    color: $pink-floyd;
    padding-bottom: 2rem;
  }

  .card{
    border: none;
    background: transparent;
    margin: 0 2em 2em;

    .card-body{
      background-color: lighten($macy-gray, 22%);

      h3{
        font-family: 'Hey August';
        text-transform: uppercase;
        font-size: 2rem;
      }
    }
  }
}

.cms-footer{
  @extend .cms-text-center;
  margin-bottom: 3rem;
  background-color: $pink-floyd;
  color: $barry-white;

  @media screen and (min-width: 992px){
    background-image: url("/themes/artsoundv2/assets/img/AS_circle_neg.svg");
    background-blend-mode: soft-light;
    background-repeat: no-repeat;
    background-position: 128%;
  }

  .image-block{
    position: relative;

    &>div{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;

      @media screen and (min-width: 576px){
        width: 30%;
        background-color: #d95773;
        mix-blend-mode: multiply;

        background-image: unset;
      }
    }

  }

  .text-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 3em;

    h2{
      font-size: 2rem;
    }
  }
}

.cms-usps{
  @extend .cms-text-center;
  .row{
    &>div{
      margin: 1em 0;
    }
  }
  h4{
    font-weight: bold;
    margin-top: .8rem;
  }

  p{
    padding: 0 1em;
  }

  img{
    max-width: 100px;
    max-height: 100px;
  }
}

.cms-projects{
  @extend .cms-text-center;
  background-color: lighten($macy-gray, 24%);
  margin-bottom: 3em;
  padding: 2em 0;

  h2{
    @extend .h1;
    color: $pink-floyd;
  }

  img{
    margin-bottom: 1em;
  }

  .slick-prev{
    left: -1rem;
  }

  .slick-next{
    right: -1rem;
  }
}

.cms-products{
  @extend .cms-text-center;
  h2{
    @extend .h1;
  }

  .card{
    border: none;

    .card-body{
      background-color: lighten($macy-gray, 22%);
    }
  }
}
