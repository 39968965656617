#adekousps{
  background-color: $barry-white;
  .adekousp{
    .img_usp{
      img{
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
}

.parallax{
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (min-width:576px){
    background-attachment: fixed;
  }
}

.color-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  mix-blend-mode: multiply;

  &.pink {
    background-color: #d95773;
  }
  &.blue {
    background-color: #85acde;
  }
  &.green {
    background-color: #2faf9f;
  }
  &.yellow {
    background-color: #cad800;
  }
}

#adekohome{
  .color-cards{
    @extend .color-overlay;
    height: 60%;
    width: 60%;

    &.top-left{
      top: 0;
      bottom: unset;
    }

    &.top-right{
      top: 0;
      right: 0;
      left: unset;
      bottom: unset;
    }

    &.bottom-right{
      right: 0;
      left: unset;
    }
  }

  .text-cards{
    @extend .color-cards;

    mix-blend-mode: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.color-card {
  @extend .color-overlay;
  width: 65%;
  height: 70%;
  padding: .7rem;
}

.blog-card {
  @extend .color-card;
  mix-blend-mode: normal;

  h3{
    font-size: 1.3rem;

    @media screen and (min-width: 576px) and (max-width: 768px){
      font-size: 1.3rem;
    }

    @media screen and (min-width: 992px) and (max-width: 1200px){
      font-size:1em;
    }

    @media screen and (min-width: 1200px){
      font-size: 1.2rem;
    }
  }
 }

#bontestimonials{
  .testimonial-img{
    margin-bottom: 0.8em;
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
}

.ls-container{
  .ls-wrapper{
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    top: 0!important;
    left: 0!important;
  }

  .ls-layer{
    &.slider-text{
      position: absolute!important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      left: 1rem !important;
      overflow-wrap: normal;

      @media screen and (max-width: 340px){
        h1{
          font-size: 2rem !important;
        }
      }


      @media screen and (min-width: 567px) and (max-width: 992px){
        left: 4rem !important;
        h1{
          font-size: 4rem !important;
        }
      }

      @media screen and (min-width: 992px){
        left: 10% !important;
        h1{
          font-size: 5rem !important;
        }
      }

      @media screen and (min-width: 1400px){
        left: 20% !important
      }
    }
  }
}