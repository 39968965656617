#module-prestablog-blog{
  #prestablogfront{
    border: none;

    time.date:before{
      border: none;
    }

    .info_blog:before{
      @extend time.date:before;
    }

    h1, h2, h3, h4, h5, h6{
      border: none;
    }

    h1 {
      @extend h1;
    }

    h2{
      @extend h2;
    }

    h3{
      @extend h3;
    }

    h4{
      @extend h4;
    }

  }

  .prestablogExtra{
    @extend #prestablogfront;

    .prestablogtitle{
      border: none;
    }

    .soc-buttons{
      display: flex;
      justify-content: center;

      li[class^='soc-']{
        display: inline-block;
        max-width: fit-content;

        a{
          padding: .5em;
          height: auto;
          width: 55px;

          i{
            margin: 0 auto;
          }
        }
      }
    }
  }

  .blog-card {
    .btn {
      padding: .375em 1.5em;
      position: absolute;
      bottom: 1em;
      display: block;
    }
  }
}