#stores{
  .postalcode{
    input[name='zip']{
      border: none;
      border-bottom: 1px solid black;
      padding: .3em 0;
    }
  }

  hr{
    border-top: 1px solid rgba($black-sabbath, .3)
  }

  .material-icons{
    font-size: 1rem;
    padding-right: 5px;
  }

  .store{
    .material-icons{
      line-height: 1.4;
    }
  }
}