#adeko-downloads{
  .download-category{
    padding-bottom: 1em;
    margin-bottom: 3em;

    .category-header{
      background-color: transparent;
      color: $black-sabbath;
      text-align: center;
    }

    .download-item{
      background-color: lighten($green-day, 25%);
      color: $barry-white;
      h3{
        font-weight: bold;
      }

      .small{
        color: lighten($barry-white, 25%);
      }
    }
  }
}