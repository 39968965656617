.l-header{
  position: fixed;
  z-index: 300;
  width: 100%;
  color: $white;

  button{
    color: $white !important;
  }

  a{
    color: $white;
  }

  img{
    filter: invert(1);

    @media screen and (max-width:576px){
      max-width: 110px;
    }
  }

  .header__navfullwidth{
    position: relative;

    .header__search{

      #searchbar{
        background-color: transparent;
        position: fixed;
        top: 60px;
        left: 50%;
        width: 100vw;
        z-index: 300;
        transform: translateX(-50%);

        @media screen and (min-width: 992px){
          position: absolute;
          width: 100%;
          top: 0;
          left: 2em;
          background-color: $white;
          transform: unset;
        }

        .col{
          border-bottom: 1px solid $white;

          @media screen and (min-width: 992px){
            border-bottom: 1px solid $black;
          }
        }

        input.form-control{
          background-color: transparent;
          color: white;

          &::placeholder {
            color: $gray-200;
          }

          @media screen and (min-width: 992px){
            background-color: $white;
            color: $black;

            &::placeholder {
              color: $gray-400;
            }
          }
        }

      }
    }
  }

  .shopping-cart{
    position: relative;

    .blockcart__count{
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $primary;
      color: white;
      padding: .1em .2em;
      line-height: .9;
      border-radius: 3px;
    }
  }

  &:hover, &.scroll, &.page-no-index{
    background-color: $header-bg;
    margin-bottom: $spacer;
    box-shadow: $header-box-shadow;
    color: unset;

    button{
      color: unset !important;
    }

    a{
      color: unset;
    }

    img{
      filter: invert(0);
    }

    .header__navfullwidth{
      .header__search{
        #searchbar{
          background-color: white;

          @media screen and (max-width: 992px){
            box-shadow: 0 4px 3px -2px rgba(0,0,0,.2);
          }

          .col{
            border-bottom: 1px solid $black;
          }
          input.form-control{
            color: $black;
            border: none;

            &::placeholder {
              color: $gray-400;
            }
          }
        }
      }
    }
  }

  &.page-no-index{
    position: relative;
  }
}

body:not(#index) #wrapper{
  padding-top: 80px;

  @media screen and (min-width: 992px){
    padding-top: 96px;
  }
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: $spacer*1.25;
  padding-bottom: $spacer*1.25;
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
    width: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}