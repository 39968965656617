.slick-arrow {
    position: absolute;
    font-family: "Hey August", Arial, sans-serif;
    font-size: 3em;
    cursor: pointer;
    top:0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .3em;
    z-index: 1;

    & > i{
        font-size: 2*$font-size-base;
        line-height: 1;
        background: #fff;
        border-radius: 50%;
    }

 }

.slick-prev {
    left:0;
    @media screen and (min-width: 567px) and(max-width:992px){
        left: -2rem;
    }
}
.slick-next{
    right:0;
    @media screen and (min-width: 567px) and(max-width:992px){
        right: -2rem;
    }
}
.slick-slide figure{
    margin: 0;
}
.slick__arrow-outside {
    .slick-prev {
        transform:translateX(-50px);
    }
    .slick-next{
        transform:translateX(50px);
    }
}
.slick__arrow-large{
    .slick-arrow i{
        font-size: 3*$font-size-base;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        background: transparent;
        color: #fff;
    }
}

.blog-slider .slick-slide > div{
    display: flex;
}