.product-page-headers{
  @media screen and (min-width: 992px){
   font-size: 2.75rem;
 }
}

#show_table, #show_downloads{
    color: $green-day;
    &:hover{
      color: darken($green-day, 25%);
    }

}

.product-tabs{
  .nav-tabs{
    .nav-link{
      color: $black-sabbath;

      i{
        font-family: "Hey August", Arial, sans-serif;
      }

      &.active{
        border-bottom-width: 1px;
        color: $green-day;

        i{
          display: none;
        }
      }
    }
  }
}

.bulletpoint-list{
  li{
    display: flex;
  }
}

.blockreassurance_product{
  &>div{
    display: flex;
    margin-bottom: .5em;
    align-items: center;

    .item-product{
      padding-top: 0;
    }

    p.block-title{
      line-height: 35px;
      margin-bottom: 0;
    }
  }
}

.featured-products{
  .card{
    border: none;
  }
}

#product{
  .block-description{
    color: $barry-white;
    background-position: center center;
    background-size: cover;
  }
}