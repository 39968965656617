.l-footer {
  color: $barry-white;


  .cms-page-link,
  .category-link,
  .account-list a{
    color: $barry-white;
    line-height: 2;
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
}

.footer__title{
  color:$barry-white;
  &:visited,&:hover{
    color: $barry-white;
  }
  text-transform: capitalize;
  display: block;
  font-weight: 700;
  margin-bottom: $spacer/2;
  font-size: 1.3 * $font-size-base;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}

.footer__copyright {
  text-align: center;
  font-size: $font-size-sm;
}
